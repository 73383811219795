import Lenis from 'lenis';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Flip } from 'gsap/Flip';

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(Flip);
gsap.registerPlugin(ScrollTrigger);

///////////////////////////////////////////////////
// RESPONSIVE FUNCTIONS / JAVASCRIPT
///////////////////////////////////////////////////

let mm = gsap.matchMedia();

///////////////////////////////////////////////////
// LENIS SMOOTH SCROLL + GSAP
///////////////////////////////////////////////////



const lenis = new Lenis()

lenis.on('scroll', (e) => {
  console.log(e)
})

lenis.on('scroll', ScrollTrigger.update)

gsap.ticker.add((time)=>{
  lenis.raf(time * 1000)
})

gsap.ticker.lagSmoothing(0)

/*

const initSmoothScrolling = () => {
    lenis = new Lenis({
        lerp: 0.25,
        smooth: true,
        
    });
    lenis.on('scroll', () => ScrollTrigger.update());
    const scrollFn = (time) => {
        
        lenis.raf(time);
        requestAnimationFrame(scrollFn);
    };
    requestAnimationFrame(scrollFn);
};

initSmoothScrolling();

*/
///////////////////////////////////////////////////
// GSAP SCROLL TRIGGER EVENTS
///////////////////////////////////////////////////

// Scroll Animations
const scroll = () => {
    console.log('scrolling scrolling scrolling...')
};

scroll();

///////////////////////////////////////////////////
// TOUCH? - to finish
///////////////////////////////////////////////////

function is_touch_enabled() {
	return ( 'ontouchstart' in window ) ||
		   ( navigator.maxTouchPoints > 0 ) ||
		   ( navigator.msMaxTouchPoints > 0 );
}
if( is_touch_enabled() ) {
	console.log('ohh touchy');
} else {
	console.log('no touchy');
}

///////////////////////////////////////////////////
// Functions init with Media Query
///////////////////////////////////////////////////

mm.add("(max-width: 64.0625em)", () => {
    console.log("tiny tiny < 64em screen");


});
mm.add("(min-width: 64.0625em)", () => {
    console.log("Big boy > 64.0625em screen");
});

///////////////////////////////////////////////////
// SWIPER
///////////////////////////////////////////////////

const swiper = new Swiper('.swiper', {
    // Optional parameters
    direction: 'horizontal',
    Draggable: true,
    spaceBetween: 20,
    centeredSlides: true,
  
    // If we need pagination
    pagination: false, /*{
      el: '.swiper-pagination',
      clickable: true,
    },*/
  
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    //breakpoints
    breakpoints: {
        834: {
          slidesPerView: 1, /* Was 2 */
        },
        1280: {
            slidesPerView: 1,  /* Was 2 */
            spaceBetween: 0, /* Was 40 */
        },
        1400: {
            slidesPerView: 1, /* Was 2 */
            spaceBetween: 0, /* Was 50 */
        },
      },
});

   

///////////////////////////////////////////////////
// Menu
///////////////////////////////////////////////////

document.addEventListener("DOMContentLoaded", function () {
  const toggleButton = document.querySelector(".burger");
  const navWrapper = document.querySelector("nav");
  let isOpen = false;
  gsap.set(".menu-item p", {y: 225})

  const timeline = gsap.timeline({ paused: true });

  timeline.to(".overlay", {
    duration: 0.75,
    clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
    ease: "power4.inOut"
  });
  timeline.to(".overlay li", {
    duration: 0.75,
    opacity: 1,
    stagger: 0.2,
    ease: "power4.out"
  }, "-=1");
  toggleButton.addEventListener("click", function () {
    this.classList.toggle("active");
    navWrapper.classList.toggle("on");
    if (isOpen) {
      timeline.reverse();
    } else {
      timeline.play();
    }
    isOpen = !isOpen;
  });
});

///////////////////////////////////////////////////
// NAV
///////////////////////////////////////////////////

let groups = gsap.utils.toArray(".accordion--group");
let menus = gsap.utils.toArray(".accordion--menu");
let menuToggles = groups.map(createAnimation);

menus.forEach((menu) => {
  menu.addEventListener("click", () => toggleMenu(menu));
});

function toggleMenu(clickedMenu) {
  menuToggles.forEach((toggleFn) => toggleFn(clickedMenu));
}

function createAnimation(element) {
  let menu = element.querySelector(".accordion--menu");
  let box = element.querySelector(".accordion--content");
  let arrow = element.querySelector(".nav--arrow");

  gsap.set(box, { height: "auto" });

  let animation = gsap
    .timeline()
    .from(box, {
      height: 0,
      duration: 0.25,
      ease: "power1.inOut"
    })
    .from(arrow, { duration: 0.2, rotate:-90, autoAlpha:0.7, ease:"none" }, 0)
    .to(arrow, { duration: 0.2, rotate: 90, ease:"none" }, 0)
    .reverse();

  return function (clickedMenu) {
    if (clickedMenu === menu) {
      animation.reversed(!animation.reversed());
    } else {
      animation.reverse();
    }
  };
}




///////////////////////////////////////////////////
// ScrollTo
///////////////////////////////////////////////////

document.querySelectorAll(".waypoint").forEach(anchor => {
  anchor.addEventListener("click", function(e) {
    e.preventDefault();
    let targetElem = document.querySelector(e.target.getAttribute("href")),
      y = targetElem;
    //gsap.to(window, {duration: 1, scrollTo:{y:"#section" + (index + 1), offsetY:70}});
    gsap.to(window, {
      scrollTo: {
        y: y,
        autoKill: false,
        offsetY:120
      },
      duration: 1
    });
  });
});



///////////////////////////////////////////////////
// Parallax items
///////////////////////////////////////////////////

/*
document.addEventListener("mousemove", parallax);
function parallax(event) {
  this.querySelectorAll(".hero--header .shape").forEach((shift) => {
    const position = shift.getAttribute("value");
    const x = (window.innerWidth - event.pageX * position) / 90;
    const y = (window.innerHeight - event.pageY * position) / 90;

    shift.style.transform = `translateX(${x}px) translateY(${y}px)`;
  });
}
*/